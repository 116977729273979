import { DayQrCodeDTO, DayQrCodeEditInput } from "@/types";
import axios from "axios";
import { defineStore } from "pinia";

interface State {
  dayQrCodes: Record<string, DayQrCodeDTO | undefined>;
  dateLoadedMap: Record<string, boolean>;
}

export const useDayQrCodeStore = defineStore("dayQrCode", {
  state: (): State => ({
    dayQrCodes: {},
    dateLoadedMap: {},
  }),
  actions: {
    async fetchDayQrCode(date: string) {
      if (this.dateLoadedMap[date] === false) {
        return;
      }
      if (this.dateLoadedMap[date] === undefined) {
        this.dateLoadedMap = {
          ...this.dateLoadedMap,
          [date]: false,
        };
      }
      const { data }: { data: DayQrCodeDTO } = await axios.get(
        `day-qr-code?date=${date}`
      );
      this.setDayQrCodes([data]);
      if (this.dateLoadedMap[date] === false) {
        this.dateLoadedMap = {
          ...this.dateLoadedMap,
          [date]: true,
        };
      }
    },
    async createOrUpdateDayQrCode(
      date: string,
      dayQrCode: DayQrCodeEditInput
    ): Promise<void> {
      const { data }: { data: DayQrCodeDTO } = await axios.post(
        `day-qr-code?date=${date}`,
        dayQrCode
      );
      this.setDayQrCodes([data]);
    },
    setDayQrCodes(dayQrCodes: DayQrCodeDTO[]): void {
      dayQrCodes.forEach((dayQrCode) => {
        this.dayQrCodes = {
          ...this.dayQrCodes,
          [dayQrCode.date]: dayQrCode,
        };
      });
    },
    handleWsUpdate(date: string) {
      if (this.dateLoadedMap[date]) {
        this.fetchDayQrCode(date);
      }
    },
  },
  debounce: {
    fetchDayQrCode: 300,
  },
});
