import { Patient, PatientInput } from "@/types";
import axios from "axios";
import { defineStore } from "pinia";
import { useAppointmentStore } from "./appointment";
import { useAppointmentCalendarStore } from "./appointmentCalendar";

interface State {
  patients: Record<string, Patient | undefined>;
  patientsByTaiwanId: Record<string, Patient | undefined>;
}

let abortController: AbortController | undefined;

export const usePatientStore = defineStore("patient", {
  state: (): State => ({
    patients: {},
    patientsByTaiwanId: {},
  }),
  actions: {
    async fetchPatient(id: string, force = false) {
      let patient = this.patients[id];
      if (!patient || force) {
        const { data }: { data: Patient | undefined } = await axios.get(
          `patients/${id}`
        );
        patient = data;
        if (patient) {
          this.setPatients([patient]);
        }
      }
      return patient;
    },
    async updatePatient(
      patientId: string,
      payload: PatientInput
    ): Promise<void> {
      const { data: patient }: { data: Patient } = await axios.put(
        `patients/${patientId}`,
        payload
      );
      this.setPatients([patient]);
      const { selectedAppointmentGroupId } = useAppointmentCalendarStore();
      const appointmentStore = useAppointmentStore();
      if (selectedAppointmentGroupId) {
        // 為了要更新新生兒約診格子的名字底下的生日
        await appointmentStore.fetchAppointmentsForAppointmentGroup(
          selectedAppointmentGroupId
        );
      }
    },
    async checkMamahealthStatus(patientId: string): Promise<void> {
      const { data: patient }: { data: Patient } = await axios.put(
        `/patients/${patientId}/check-mamahealth-status`
      );
      this.setPatients([patient]);
      const { selectedAppointmentGroupId } = useAppointmentCalendarStore();
      const appointmentStore = useAppointmentStore();
      if (selectedAppointmentGroupId) {
        // 為了要更新新生兒約診格子的名字底下的生日
        await appointmentStore.fetchAppointmentsForAppointmentGroup(
          selectedAppointmentGroupId
        );
      }
    },
    async searchPatients(params: {
      chineseName?: string;
      taiwanId?: string;
      patientId?: string;
      email?: string;
      phoneNumber?: string;
    }): Promise<Patient[]> {
      abortController?.abort();
      abortController = new AbortController();
      try {
        const { data }: { data: Patient[] } = await axios.get(
          "patients/search",
          {
            params,
            signal: abortController.signal,
          }
        );
        this.setPatients(data);
        return data;
      } catch (e) {
        return [];
      }
    },
    setPatients(patients: Patient[]) {
      const patientsMap: { [patientId: string]: Patient } = {};
      patients.forEach((patient) => {
        patientsMap[patient._id] = patient;
        if (!patient.taiwanId) return;
        this.patientsByTaiwanId[patient.taiwanId] = patient;
      });
      this.patients = { ...this.patients, ...patientsMap };
    },
    handleWsCreate(id: string) {
      if (this.patients[id]) {
        this.fetchPatient(id);
      }
    },
  },
});
