<template>
  <div class="text-center">
    <v-progress-circular
      v-if="isLoadingTimeSlots"
      indeterminate
      color="primary"
      class="center-vertical"
    ></v-progress-circular>
    <table
      v-else-if="sites.length > 0"
      border="1"
      style="width: 100%"
      class="appt-table"
    >
      <tr style="position: sticky; top: 0; background-color: white; z-index: 1">
        <th class="time-col"></th>
        <th
          v-for="site in sites"
          :key="site"
          :colspan="siteColumnCounts.get(site) || 0"
        >
          {{ SHARED_LABELS.clinicSite[site] }}
        </th>
      </tr>
      <tr v-for="time in times" :key="time">
        <td class="time-col">{{ time }}</td>
        <template v-for="site in sites">
          <template v-for="doctorId in doctorIdsBySite.get(site)">
            <td
              v-for="calendarEvent in getEvents(site, doctorId, time)"
              :key="calendarEvent.calendarEventId"
              :style="`background-color: ${calendarEvent.backgroundColor}`"
              :class="{
                actionable: calendarEvent.isActionable,
                focused: calendarEvent.focused,
              }"
              @click="handleCalendarEventClick(calendarEvent)"
            >
              <div
                v-if="calendarEvent.label"
                class="calendar-event d-flex flex-column justify-center"
                :class="{
                  'pending-response': calendarEvent.isPendingResponse,
                  'request-to-cancel': calendarEvent.isRequestingToCancel,
                }"
              >
                <div class="patient-name" :title="calendarEvent.remarks">
                  <span v-if="calendarEvent.showStar">*</span>
                  {{ calendarEvent.label }}
                  <v-icon v-if="calendarEvent.isVirtual" small
                    >mdi-monitor-account</v-icon
                  >
                </div>
                <div>{{ calendarEvent.weekLabel }}</div>
                <div
                  v-if="calendarEvent.focused"
                  class="selected-appointment-box"
                ></div>
              </div>
              <v-icon v-else-if="calendarEvent.isChecked">mdi-check</v-icon>
            </td>
          </template>
        </template>
      </tr>
    </table>
    <div
      v-else
      class="d-flex justify-center text-center flex-column"
      style="height: 500px"
    >
      <v-icon :size="200"> mdi-alert-circle-outline </v-icon>
      <div>尚未開放門診表！</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LABELS as SHARED_LABELS } from "@/shared/sharedLabels";
import {
  Appointment,
  AppointmentCalendarEvent,
  TimeSlot,
} from "@/shared/sharedTypes";
import { computed, onMounted } from "vue";
import { useUrlSearchParams } from "@vueuse/core";
import {
  getPregnancyWeek,
  getPregnantDays,
  getAge,
} from "@/shared/appointmentHelpers";
import { useDoctorStore } from "@/stores/doctor";
import hexRgb from "hex-rgb";
import rgbHex from "rgb-hex";
import { storeToRefs } from "pinia";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { useAppointmentStore } from "@/stores/appointment";
import { CheckType, ClinicSite } from "@/types";
import moment from "moment";
import { useTimeSlotStore } from "@/stores/timeSlot";
import { generateOrderTableLabelSuffix } from "@/utils/generateFollowUpLabel";
import {
  calendarAdditionalTimeList,
  calendarTimeList,
} from "@/utils/constants";

const doctorStore = useDoctorStore();
const timeSlotStore = useTimeSlotStore();
const { dateLoadedMap } = storeToRefs(timeSlotStore);
const apptCalendarStore = useAppointmentCalendarStore();
const { checkTypesMetadata, selectedCheckTypes, selectedAppointmentId } =
  storeToRefs(apptCalendarStore);
const appointmentStore = useAppointmentStore();

const isLoadingTimeSlots = computed(() => {
  return !dateLoadedMap.value[apptCalendarStore.date];
});

const times = computed(() => {
  const rawTimes = apptCalendarStore.timeSlotsForCurrentDate.map(
    (timeSlot) => timeSlot.start.slice(11, 16) // Get the time in format like 08:00
  );
  const timeSet = new Set(rawTimes);
  const reversedAdditionalTimeList = [...calendarAdditionalTimeList].reverse();
  let index = 0;
  for (const time of reversedAdditionalTimeList) {
    if (timeSet.has(time)) {
      break;
    }
    index++;
  }
  const splicedAdditionalTimeList = reversedAdditionalTimeList
    .splice(index, reversedAdditionalTimeList.length - index)
    .reverse();
  const merged = [...calendarTimeList, ...splicedAdditionalTimeList];
  return merged;
});

const sites = computed((): ClinicSite[] => {
  const siteSet = new Set<ClinicSite>();
  const sites = apptCalendarStore.timeSlotsForCurrentDate.map(
    (timeSlot) => timeSlot.site
  );
  const orderedSites: ClinicSite[] = [
    "new_taipei",
    "taipei",
    "chupei",
    "yuanlin",
    "miaoli",
  ];
  orderedSites.forEach((site) => {
    if (sites.includes(site)) {
      siteSet.add(site);
    }
  });
  return Array.from<ClinicSite>(siteSet);
});

const timeSlotsBySite = computed((): Map<ClinicSite, TimeSlot[]> => {
  const map = new Map<ClinicSite, TimeSlot[]>();
  apptCalendarStore.timeSlotsForCurrentDate.forEach((timeSlot) => {
    const currentArr = map.get(timeSlot.site) || [];
    currentArr.push(timeSlot);
    map.set(timeSlot.site, currentArr);
  });
  return map;
});

const siteColumnCounts = computed((): Map<ClinicSite, number> => {
  const maxCapacityBySite = new Map<ClinicSite, number>();
  timeSlotsBySite.value.forEach((timeSlots, site) => {
    const maxCapacityByDoctorMap = new Map<string, number>();
    timeSlots.forEach((timeSlot) => {
      let count = maxCapacityByDoctorMap.get(timeSlot.doctorId) || 0;
      count = Math.max(count, timeSlot.capacity);
      maxCapacityByDoctorMap.set(timeSlot.doctorId, count);
    });
    let maxCapacity = 0;
    maxCapacityByDoctorMap.forEach((capacity) => {
      maxCapacity += capacity;
    });
    maxCapacityBySite.set(site, maxCapacity);
  });
  return maxCapacityBySite;
});

const doctorIdsBySite = computed((): Map<ClinicSite, string[]> => {
  const rawDoctorIdsBySite = new Map<ClinicSite, Set<string>>();
  const doctorIdsBySite = new Map<ClinicSite, string[]>();
  sites.value.forEach((site) => {
    timeSlotsBySite.value.get(site)?.forEach((timeSlot) => {
      const existingSet = rawDoctorIdsBySite.get(site) || new Set<string>();
      existingSet.add(timeSlot.doctorId);
      rawDoctorIdsBySite.set(site, existingSet);
    });
    const doctorIdSet = rawDoctorIdsBySite.get(site);
    if (!doctorIdSet) return;
    doctorIdsBySite.set(
      site,
      doctorStore.sortedDoctors
        .filter((doctor) => doctorIdSet.has(doctor._id))
        .map((doctor) => doctor._id)
    );
  });
  return doctorIdsBySite;
});

const timeSlotBySiteAndDoctorAndStartTime = computed(
  (): Map<string, TimeSlot> => {
    const map = new Map<string, TimeSlot>();
    apptCalendarStore.timeSlotsForCurrentDate.forEach((timeSlot) => {
      const key = `${timeSlot.site}#${
        timeSlot.doctorId
      }#${timeSlot.start.substring(11, 16)}`;
      map.set(key, timeSlot);
    });
    return map;
  }
);

const maxCapacityBySiteAndDoctor = computed((): Map<string, number> => {
  const maxCapacityBySiteAndDoctorMap = new Map<string, number>();
  apptCalendarStore.timeSlotsForCurrentDate.forEach((timeSlot) => {
    let count =
      maxCapacityBySiteAndDoctorMap.get(
        `${timeSlot.site}#${timeSlot.doctorId}`
      ) || 0;
    count = Math.max(count, timeSlot.capacity);
    maxCapacityBySiteAndDoctorMap.set(
      `${timeSlot.site}#${timeSlot.doctorId}`,
      count
    );
  });
  return maxCapacityBySiteAndDoctorMap;
});

const getTimeSlotColor = (doctorId: string, isActionable: boolean): string => {
  const doctor = doctorStore.doctors[doctorId];
  if (doctor && doctor.color) {
    const { red, green, blue } = hexRgb(doctor.color);
    return `#${rgbHex(red, green, blue, !isActionable ? 0.3 : 1)}`;
  }
  return "#FFFFFF";
};

const getEvents = (site: ClinicSite, doctorId: string, time: string) => {
  const calendarEvents: AppointmentCalendarEvent[] = [];

  // Get source time slot
  const timeSlot = timeSlotBySiteAndDoctorAndStartTime.value.get(
    `${site}#${doctorId}#${time}`
  );
  const maxCapacity =
    maxCapacityBySiteAndDoctor.value.get(`${site}#${doctorId}`) || 0;

  const isSiteValidForCurrentSelection =
    !apptCalendarStore.validSitesForCurrentSelection ||
    apptCalendarStore.validSitesForCurrentSelection.includes(site);

  if (timeSlot) {
    // Populate calendar events with appointments
    timeSlot.appointmentIds?.forEach((appointmentId) => {
      const appointment = appointmentStore.appointments[appointmentId];
      if (appointment) {
        let label = `${appointment.chineseName} `;
        label = `${label}${generateOrderTableLabelSuffix(appointment)}`;
        if (appointment.remarks) {
          label = `${label}[註]`;
        }
        const isActionable = getIsActionable(
          timeSlot,
          isSiteValidForCurrentSelection,
          appointment
        );

        const calendarEvent: AppointmentCalendarEvent = {
          category: timeSlot.site,
          doctorId: timeSlot.doctorId,
          timeSlotId: timeSlot._id,
          calendarEventId: `${timeSlot._id}#${appointmentId}#BOOKABLE#${calendarEvents.length}`,
          appointmentId,
          appointmentGroupId: appointment.appointmentGroupId,
          patientId: appointment.patientId,
          label,
          isChecked: false,
          isCheckedIn: !!appointment.isCheckedIn,
          backgroundColor: getTimeSlotColor(timeSlot.doctorId, isActionable),
          focused: apptCalendarStore.selectedAppointmentId == appointment._id,
          isActionable,
          remarks: appointment.remarks,
          isConfirmed: appointment.isConfirmed === true,
          isRequestingToCancel: appointment.isConfirmed === false,
          isPendingResponse:
            appointment.smsSent === true &&
            appointment.isConfirmed === undefined,
          isVirtual: !!appointment.googleMeet,
        };
        if (appointment.appointmentGroupCategory === "fetal") {
          if (
            (["chupei", "yuanlin", "miaoli"] satisfies ClinicSite[]).includes(
              appointment.site as any
            )
          ) {
            const pregnantDays = getPregnantDays(
              appointment.date,
              appointment.dueDate
            );
            if (pregnantDays) {
              const inRange1 = pregnantDays >= 77 && pregnantDays <= 96; //11W0D ~ 13W5D
              const inRange2 = pregnantDays >= 119 && pregnantDays <= 139; // 17W0D ~ 19W6D
              if (inRange1 || inRange2) {
                calendarEvent.showStar = true;
              }
            }
          }
          calendarEvent.weekLabel = getPregnancyWeek(
            appointment.date,
            appointment.dueDate
          );
        } else {
          calendarEvent.weekLabel = getAge(appointment.date, appointment.dob);
        }
        calendarEvents.push(calendarEvent);
      }
    });

    let capacity = timeSlot.capacity;
    if (selectedCheckTypes?.value?.length) {
      const patientHasAppointment = !!calendarEvents.find(
        (calendarEvent) =>
          calendarEvent.patientId === apptCalendarStore.selectedPatientId
      );
      if (patientHasAppointment) {
        capacity = 1;
      } else {
        capacity = Math.min(timeSlot.capacity, calendarEvents.length + 1);
      }
    }

    // If time slot still has capacity, top it up with bookable calendar events
    while (calendarEvents.length < capacity) {
      const calendarEventId = `${timeSlot._id}#BOOKABLE#${calendarEvents.length}`;
      const isChecked =
        !!checkTypesMetadata.value &&
        !!Object.keys(checkTypesMetadata.value).find((checkType) => {
          const metadata = checkTypesMetadata.value?.[checkType as CheckType];
          return metadata?.calendarEventId === calendarEventId;
        });
      const isActionable = getIsActionable(
        timeSlot,
        isSiteValidForCurrentSelection
      );
      calendarEvents.push({
        calendarEventId,
        category: site,
        doctorId: doctorId,
        timeSlotId: timeSlot._id,
        isChecked,
        backgroundColor: getTimeSlotColor(timeSlot.doctorId, isActionable),
        isActionable,
        // !!selectedCheckTypes?.value && isSiteValidForCurrentSelection,
        isCheckedIn: false,
        isConfirmed: false,
        isRequestingToCancel: false,
        isPendingResponse: false,
        isVirtual: false,
      });
    }
  }

  // If capacity is lower than max capacity, top it up with unbookable calendar events
  while (calendarEvents.length < maxCapacity) {
    calendarEvents.push({
      calendarEventId: `UNBOOKABLE#${site}#${doctorId}#${time}#${calendarEvents.length}`,
      category: site,
      isChecked: false,
      backgroundColor: "white",
      isActionable: false,
      isCheckedIn: false,
      isConfirmed: false,
      isRequestingToCancel: false,
      isPendingResponse: false,
      isVirtual: false,
    });
  }

  return calendarEvents;
};

const getIsActionable = (
  timeSlot: TimeSlot,
  isSiteValidForCurrentSelection: boolean,
  appointment?: Appointment
) => {
  // If rescheduling, all existing appointments cannot be selected
  if (apptCalendarStore.rescheduleFromAppointmentId && appointment) {
    return false;
  }

  // If not in selection mode, an appointment is always actionable
  const isSelecting = !!apptCalendarStore.selectedCheckTypes?.length;
  if (!isSelecting) {
    return true;
  }

  const isInThePast =
    moment(timeSlot.start.slice(0, 10)).diff(
      moment().format("YYYY-MM-DD"),
      "days"
    ) < 0;

  // Ensure the selected slot is either not full or belongs to the same appointment group
  const hasSpace = timeSlot.capacity > (timeSlot.appointmentIds?.length ?? 0);
  const isSameAppointmentGroup =
    !!apptCalendarStore.selectedAppointmentGroupId &&
    apptCalendarStore.selectedAppointmentGroupId ===
      appointment?.appointmentGroupId;
  const doesSlotHaveSpace = isSameAppointmentGroup || hasSpace;

  const canBeBooked =
    isSelecting &&
    (!appointment ||
      appointment.patientId === apptCalendarStore.selectedPatientId);

  return (
    canBeBooked &&
    !isInThePast &&
    isSiteValidForCurrentSelection &&
    doesSlotHaveSpace
  );
};

const handleCalendarEventClick = (
  calendarEvent: AppointmentCalendarEvent
): void => {
  const {
    appointmentId,
    timeSlotId,
    isActionable,
    patientId,
    appointmentGroupId,
  } = calendarEvent;
  // If selecting, don't allow users to select blocked events
  if (!isActionable) {
    return;
  }
  if (!!selectedCheckTypes?.value && timeSlotId) {
    apptCalendarStore.setTimeSlotCheckType(
      timeSlotId,
      calendarEvent.calendarEventId,
      appointmentId
    );
  } else if (appointmentId && patientId && appointmentGroupId) {
    selectedAppointmentId.value = appointmentId;
    if (appointmentId && patientId && appointmentGroupId) {
      apptCalendarStore.selectAppointment(
        appointmentId,
        patientId,
        appointmentGroupId
      );
    }
  }
};

onMounted(async () => {
  const { appointmentId: appointmentIdFromQueryParam } = useUrlSearchParams<{
    appointmentId?: string;
  }>();
  if (appointmentIdFromQueryParam) {
    const appointment = await appointmentStore.fetchAppointment(
      appointmentIdFromQueryParam
    );
    if (appointment) {
      apptCalendarStore.setDate(
        new Date(appointment.start).toISOString().split("T")[0]
      );
      await apptCalendarStore.selectAppointment(
        appointmentIdFromQueryParam,
        appointment.patientId,
        appointment.appointmentGroupId
      );
    }
  } else {
    apptCalendarStore.fetchTimeSlotsForCurrentDate();
    apptCalendarStore.fetchDayRemarkForCurrentDate();
  }
});
</script>

<style lang="scss" scoped>
$circle-size: 30px;

.appt-table {
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
  font-size: 14px;

  td,
  th {
    text-align: center;
    min-width: 50px;
    border: 1px solid #e0e0e0;
    height: 44px;
    &.actionable {
      cursor: pointer;
    }
  }
  .time-col {
    width: 50px;
  }
}

.appt-table .time-slot-container {
  height: 100%;
  user-select: none;

  &.focused {
    border: 2px solid red;
  }
}

.patient-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-event {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.checked-in-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.center-vertical {
  position: absolute;
  top: 40%;
}

.selected-appointment-box {
  border: 2px solid red;
  height: 100%;
  width: 100%;
  position: absolute;
}

.appt-table th {
  height: 26px;
}

.pending-response {
  color: blue;
}

.request-to-cancel {
  color: red;
}
</style>
