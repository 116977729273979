<template>
  <v-dialog
    :value="value"
    width="400"
    @keydown.esc="handleClose"
    @click:outside="handleClose"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        {{ date }} 人力表
        <v-spacer />
      </v-toolbar>
      <v-card-text class="mt-8">
        <v-row>
          <v-col v-if="isLoading" class="text-center">
            <v-progress-circular indeterminate color="primary" />
          </v-col>
          <v-col v-else-if="workingStaff.length" md="12">
            <table width="100%">
              <tr>
                <th class="text-left">姓名</th>
                <th class="text-left">院區</th>
                <th class="text-left">備註</th>
              </tr>
              <tr v-for="staff in workingStaff" :key="staff.name">
                <td>{{ staff.name }}</td>
                <td>{{ staff.location }}</td>
                <td>{{ staff.job_notes }}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useStaffStore } from "@/stores/staff";
import { computed, ref, watch } from "vue";

interface PropType {
  value: boolean;
  date: string;
}

const props = defineProps<PropType>();
const emit = defineEmits(["close"]);
const staffStore = useStaffStore();
const isLoading = ref(false);

watch(
  () => props.value,
  () => {
    loadStaffList();
  }
);

const loadStaffList = async () => {
  if (props.value) {
    isLoading.value = true;
    await staffStore.fetchWorkingStaff(props.date);
    isLoading.value = false;
  }
};

const workingStaff = computed(() => {
  return staffStore.workingStaffByDate[props.date] ?? [];
});

const handleClose = () => {
  emit("close");
};
</script>
