<template>
  <div>
    <v-toolbar flat color="white" dense class="mb-1">
      <v-btn-toggle v-model="calendarType" class="mr-2" dense>
        <v-btn
          v-for="supportedType in supportedTypes"
          :key="supportedType"
          :value="supportedType"
          class="white"
        >
          {{ LABELS[supportedType] }}
        </v-btn>
      </v-btn-toggle>
      <v-btn outlined class="mr-2" height="36" @click="handleTodayClick">
        {{ LABELS.today }}
      </v-btn>
      <v-btn
        v-if="appointmentCalendarStore.calendarType === CalendarType.CATEGORY"
        outlined
        class="mr-2"
        height="36"
        @click="openOrderTablePage"
      >
        {{ LABELS.orderTable }}
      </v-btn>
      <v-btn
        v-if="appointmentCalendarStore.calendarType === CalendarType.CATEGORY"
        outlined
        class="mr-2"
        :class="{
          red: !!remark,
          'white--text': !!remark,
        }"
        height="36"
        @click="handleRemarksClick"
      >
        備註
      </v-btn>
      <v-btn
        v-if="appointmentCalendarStore.calendarType === CalendarType.CATEGORY"
        outlined
        class="mr-2"
        height="36"
        @click="handleStaffClick"
      >
        人力表
      </v-btn>
      <v-btn
        v-if="appointmentCalendarStore.calendarType === CalendarType.CATEGORY"
        outlined
        class="mr-2"
        height="36"
        :loading="isLoadingEmails"
        :disabled="isLoadingEmails"
        @click="handleEmailClick"
      >
        QR管理
      </v-btn>
      <DayRemarkDialog
        v-model="showRemarksDialog"
        :remark="remark"
        :date="date"
        @close="showRemarksDialog = false"
      />
      <StaffDialog
        v-model="showStaffDialog"
        :date="date"
        @close="showStaffDialog = false"
      />
      <NewTaipeiQRCodeManagement
        v-model="showNewTaipeiQRCodeManagementDialog"
        :date="date"
        @close="showNewTaipeiQRCodeManagementDialog = false"
      />
      <v-btn-toggle
        v-if="appointmentCalendarStore.calendarType === CalendarType.MONTH"
        v-model="selectedViewMode"
        dense
      >
        <v-btn value="show_available" outlined> 顯示可約 </v-btn>
        <v-btn value="show_all" outlined> 顯示所有 </v-btn>
      </v-btn-toggle>
    </v-toolbar>
    <v-toolbar flat color="white" dense class="mb-1">
      <v-btn fab text medium color="grey darken-2" @click="handlePrevClick">
        <v-icon medium>mdi-chevron-left</v-icon>
      </v-btn>
      <div class="d-flex align-center">
        <h3>{{ dateHeader }}</h3>
        <v-menu
          v-if="appointmentCalendarStore.calendarType === CalendarType.CATEGORY"
          v-model="showCurrentDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              class="ml-2 font-weight-bold"
              dense
              style="width: 100px; font-size: 18px"
              hide-details
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="showCurrentDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <v-btn fab text medium color="grey darken-2" @click="handleNextClick">
        <v-icon medium>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer />
      <v-menu
        v-model="showDueDatePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        left
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            :value="dueDate"
            :label="LABELS.dueDate"
            class="due-date-text-field"
            hide-details
            type="date"
            max="2999-12-31"
            outlined
            dense
            style="min-width: 115px; max-width: 115px"
            v-bind="attrs"
            v-on="on"
            @change="handleDueDateChanged"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dueDate"
          @input="showDueDatePicker = false"
        ></v-date-picker>
      </v-menu>
      <span v-if="dueDate">
        <v-btn-toggle v-model="weekToDisplay" class="ml-2">
          <v-btn
            v-for="week in availableWeeks"
            :key="week"
            class="white"
            height="36"
            small
          >
            {{ week }}
          </v-btn>
        </v-btn-toggle>
      </span>
    </v-toolbar>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { CalendarType } from "@/shared/sharedEnums";
import { useDoctorStore } from "@/stores/doctor";
import { pregnantWeek } from "@/utils/utils";
import { computed, onMounted, ref } from "vue";
import moment from "moment-timezone";
import { LABELS } from "./calendarLabels";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { storeToRefs } from "pinia";
import { useAppointmentStore } from "@/stores/appointment";
import {
  Appointment,
  MonthlyCalendarViewMode,
  OrderTableRow,
  TimeSlot,
} from "@/shared/sharedTypes";
import {
  generateOrderTableLabelSuffix,
  generateOrderTableLabelPrefix,
} from "@/utils/generateFollowUpLabel";
import DayRemarkDialog from "../dayRemarkDialog/dayRemarkDialog.vue";
import StaffDialog from "../staffDialog/staffDialog.vue";
import NewTaipeiQRCodeManagement from "../newTaipeiQRCodeManagement/newTaipeiQRCodeManagement.vue";
import { useDayRemarkStore } from "@/stores/dayRemark";

interface PropType {
  supportedTypes: CalendarType[];
}

const props = defineProps<PropType>();
const emit = defineEmits(["prevClicked", "nextClicked", "weekSelected"]);
const showCurrentDatePicker = ref(false);
const showDueDatePicker = ref(false);
const _weekToDisplayIdx = ref<number | undefined>();
const availableWeeks = [11, 14, 18, 22, 28];
const showRemarksDialog = ref(false);
const showStaffDialog = ref(false);
const isLoadingEmails = ref(false);
const showNewTaipeiQRCodeManagementDialog = ref(false);

const doctorStore = useDoctorStore();
const dayRemarkStore = useDayRemarkStore();
const appointmentCalendarStore = useAppointmentCalendarStore();
const { dueDate } = storeToRefs(appointmentCalendarStore);

const calendarType = computed({
  get() {
    return appointmentCalendarStore.calendarType;
  },
  set(type: CalendarType) {
    appointmentCalendarStore.setCalendarType(type);
  },
});

const remark = computed(() => {
  return dayRemarkStore.dayRemarks[date.value]?.remark || "";
});

const selectedViewMode = computed({
  get(): MonthlyCalendarViewMode {
    return appointmentCalendarStore.monthlyCalendarViewMode;
  },
  set(viewMode: MonthlyCalendarViewMode): void {
    appointmentCalendarStore.setMonthlyCalendarViewMode(viewMode);
  },
});

const weekToDisplay = computed({
  get(): number | undefined {
    return _weekToDisplayIdx.value;
  },
  set(weekIdx: number | undefined): void {
    _weekToDisplayIdx.value = weekIdx;
    emit("weekSelected", weekIdx !== undefined);
    if (weekIdx !== undefined) {
      handleWeekClick(availableWeeks[weekIdx]);
    }
  },
});

const date = computed({
  get(): string {
    return appointmentCalendarStore.date;
  },
  set(date: string): void {
    appointmentCalendarStore.setDate(moment(date).format("YYYY-MM-DD"));
  },
});

const dateHeader = computed(() => {
  if (appointmentCalendarStore.calendarType === CalendarType.CATEGORY) {
    const momentInstance = moment(appointmentCalendarStore.date).locale(
      "zh_tw"
    );
    let label = momentInstance.format("dd");
    if (dueDate?.value) {
      label = `${label} (${pregnantWeek(
        appointmentCalendarStore.date,
        dueDate.value
      )})`;
    }
    return label;
  } else {
    if (appointmentCalendarStore.dueDate && _weekToDisplayIdx.value) {
      const days =
        280 -
        moment(appointmentCalendarStore.dueDate).diff(
          appointmentCalendarStore.date,
          "days"
        );
      return `${Math.floor(days / 7)}+${days % 7}`;
    } else {
      return appointmentCalendarStore.date.split("-").slice(0, 2).join("/");
    }
  }
});

function handleTodayClick() {
  appointmentCalendarStore.setDate(
    moment().tz("Asia/Taipei").format("YYYY-MM-DD")
  );
}

function handlePrevClick() {
  emit("prevClicked");
}

function handleNextClick() {
  emit("nextClicked");
}

function handleWeekClick(week: number) {
  if (dueDate?.value) {
    const dueDateInstance = moment(dueDate.value);
    dueDateInstance.subtract(40 - week, "weeks");
    switch (week) {
      case 22:
        dueDateInstance.subtract(3, "days");
        break;
    }
    appointmentCalendarStore.setDateRange(dueDateInstance.format("YYYY-MM-DD"));
  }
}

const handleDueDateChanged = (dueDate: string) => {
  appointmentCalendarStore.setDueDate(dueDate);
  showDueDatePicker.value = false;
};

onMounted(() => {
  doctorStore.fetchDoctors();
  if (!props.supportedTypes.includes(appointmentCalendarStore.calendarType)) {
    appointmentCalendarStore.setCalendarType(props.supportedTypes[0]);
  }
});

const openOrderTablePage = () => {
  const { doctors: doctorRecord } = useDoctorStore();
  const { timeSlotsForCurrentDate, date: currentDate } =
    useAppointmentCalendarStore();
  const { appointments: appointmentsRecord } = useAppointmentStore();
  const timeSlots = timeSlotsForCurrentDate;
  const doctors = Object.values(doctorRecord);
  doctors.sort((a, b) => a.order - b.order);
  // Group timeSlots by doctorId
  const doctorIdToTimeSlots: Record<string, { [time: string]: TimeSlot[] }> =
    {};

  for (const timeSlot of timeSlots) {
    if (!doctorIdToTimeSlots[timeSlot.doctorId]) {
      doctorIdToTimeSlots[timeSlot.doctorId] = {};
    }
    const timeString = timeSlot.start.slice(11, 16); // 2023-01-01T07:30:00
    if (!timeString) continue;
    if (!doctorIdToTimeSlots[timeSlot.doctorId][timeString]) {
      doctorIdToTimeSlots[timeSlot.doctorId][timeString] = [];
    }
    doctorIdToTimeSlots[timeSlot.doctorId][timeString].push(timeSlot);
  }
  const rows: OrderTableRow[] = [];
  const times = [
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
  ];
  // Sort timeSlots by start time
  for (const doctor of doctors) {
    const row: OrderTableRow = { key: doctor._id, cells: [] };
    const timeSlotsRecord = doctorIdToTimeSlots[doctor._id];
    if (!timeSlotsRecord) continue;
    for (const time of times) {
      const timeSlots = timeSlotsRecord[time];
      if (!timeSlots) continue;
      for (const timeSlot of timeSlots) {
        const appointments = timeSlot?.appointmentIds
          ?.map((id) => appointmentsRecord[id])
          .filter((appt): appt is Appointment => !!appt);
        if (!appointments) continue;
        for (const appointment of appointments) {
          row.cells.push({
            key: `cell_${doctor._id}_${appointment.chineseName}_${time}`,
            backgroundColor: doctor.color,
            textList: [
              `${generateOrderTableLabelPrefix(appointment)}${
                appointment.chineseName
              }${generateOrderTableLabelSuffix(appointment)}`,
              time,
            ],
          });
        }
      }
    }
    if (row.cells.length) {
      rows.push(row);
    }
  }

  // return rows;
  const htmlString = `
    <table>
      ${rows
        .map((row) => {
          return `<tr>
                    <td>
                      ${moment(currentDate).format("M/D")}<br/>
                      (總台數：${row.cells.length})
                    </td>
        ${row.cells
          .map((cell) => {
            return `<td style="background-color: ${cell.backgroundColor}">
                    ${cell.textList
                      .map((text) => `<div>${text}</div>`)
                      .join("")}
                    </td>
                    <td></td>
                    `;
          })
          .join("")}
                </tr>`;
        })
        .join("")}
    </table>
    <style>
      table,
      th,
      td {
        border: 1px solid;
      }
    </style>
    `;
  // open a new tab with the HTML string as the content
  const newTab = window.open();
  if (!newTab) return;
  newTab.document.open();
  newTab.document.write(htmlString);
  newTab.document.title = "排台表（請 Command+A 複製）";
  newTab.document.close();
};

const handleRemarksClick = () => {
  showRemarksDialog.value = true;
};

const handleStaffClick = () => {
  showStaffDialog.value = true;
};

const handleEmailClick = async () => {
  showNewTaipeiQRCodeManagementDialog.value = true;
};
</script>

<style scoped>
.fill-width {
  width: 100%;
}
::v-deep .v-toolbar__content {
  padding: 0px !important;
}

::v-deep .due-date-text-field {
  width: 50px;
}

::v-deep .v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: black !important;
}
</style>
