import ReconnectingWebSocket from "reconnecting-websocket";
import { AlertItemTypeEnum } from "../shared/sharedEnums";
import { useAppointmentRequestStore } from "../stores/appointmentRequest";
import { ConnectionDTO, WebSocketItemData } from "@/types";
import { useConnectionStore } from "@/stores/connections";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { useTransactionStore } from "@/stores/transaction";
import { useTimeSlotStore } from "@/stores/timeSlot";
import { useAppointmentGroupStore } from "@/stores/appointmentGroup";
import { useAppointmentStore } from "@/stores/appointment";
import { useDayRemarkStore } from "@/stores/dayRemark";
import { useOverviewStore } from "@/stores/overview";
import { useReceiptStore } from "@/stores/receipt";
import { usePatientStore } from "@/stores/patient";

const handleMessage = ({ data }: { data: string }) => {
  const json: WebSocketItemData = JSON.parse(data);
  switch (json.key) {
    // Connection
    case "connected": {
      useConnectionStore().setConnectId(json.connected);
      break;
    }
    case "updateConnection": {
      const connections: ConnectionDTO[] = json.updateConnection;
      useConnectionStore().setConnections(connections);
      break;
    }
    case "disconnected": {
      const connectionIds: string[] = json.disconnected;
      useConnectionStore().removeConnections(connectionIds);
      break;
    }
    // Patient
    case "createPatient": {
      usePatientStore().handleWsCreate(json.id);
      break;
    }
    case "updatePatient": {
      useAppointmentCalendarStore().handlePatientUpdate(json);
      break;
    }
    // AppointmentGroup
    case "createAppointmentGroup": {
      useAppointmentGroupStore().handleWsCreate(json.id);
      break;
    }
    case "updateAppointmentGroup": {
      useAppointmentCalendarStore().handleAppointmentGroupUpdate(json);
      break;
    }
    case "deleteAppointmentGroup": {
      useAppointmentCalendarStore().handleAppointmentGroupUpdate(json);
      break;
    }
    // TimeSlot
    case "bulkCreateTimeSlot": {
      useTimeSlotStore().handleWsCreate(json.date);
      break;
    }
    case "updateTimeSlot": {
      useAppointmentCalendarStore().handleTimeSlotUpdate(json.id);
      break;
    }
    case "deleteTimeSlot": {
      useTimeSlotStore().handleWsDelete(json.id);
      break;
    }
    // Transaction
    case "createTransaction": {
      useTransactionStore().handleWsCreate(json.id);
      break;
    }
    case "updateTransaction": {
      if (useTransactionStore().transactions[json.updateTransaction.id]) {
        useTransactionStore().fetchTransaction(json.updateTransaction.id);
      }
      break;
    }
    case "deleteTransaction": {
      if (useTransactionStore().transactions[json.updateTransaction.id]) {
        useTransactionStore().fetchTransaction(json.updateTransaction.id);
      }
      break;
    }
    // Appointment
    case "createAppointment": {
      useAppointmentStore().handleWsCreate(json.id);
      break;
    }
    case "updateAppointment": {
      useAppointmentCalendarStore().handleAppointmentUpdate(json);
      break;
    }
    case "deleteAppointment": {
      const appointmentStore = useAppointmentStore();
      const oldAppointment = appointmentStore.appointments[json.id];
      if (!oldAppointment) return;
      appointmentStore.setAppointments([
        { ...oldAppointment, isDeleted: true },
      ]);
      break;
    }
    // AppointmentRequest
    case "createAppointmentRequest": {
      useAppointmentRequestStore().handleWsNewAppointmentRequest(json.id);
      break;
    }
    case "updateAppointmentRequest": {
      useAppointmentRequestStore().handleWsUpdateAppointmentRequest(json.id);
      break;
    }
    // DayRemark
    case "createDayRemark": {
      useDayRemarkStore().handleWsUpdate(json.date);
      break;
    }
    case "updateDayRemark": {
      useDayRemarkStore().handleWsUpdate(json.date);
      break;
    }
    // Overview
    case "createOverview": {
      // Fetch overview if other person create it
      useOverviewStore().fetchOverviews(json.date, json.date);
      break;
    }
    // Receipt
    case "createReceipt": {
      useReceiptStore().handleWsCreate(json.id);
      break;
    }
  }
};

export const webSocket = new ReconnectingWebSocket(process.env.VUE_APP_WS_URL!);

webSocket.onopen = async () => {
  const { refreshConnections } = useConnectionStore();
  await refreshConnections();
  checkCurrentConnection();
};

export const getRoomId = (type: AlertItemTypeEnum, typeId: string) =>
  `${type}#${typeId}`;

export const checkCurrentConnection = () => {
  webSocket.send(
    JSON.stringify({
      action: "checkconnection",
    })
  );
};

webSocket.onmessage = handleMessage;

webSocket.onclose = () => {
  const connectionStore = useConnectionStore();
  connectionStore.setConnectId();
  console.log("WebSocket closed. Reconnecting...");
};

webSocket.onerror = (error) => {
  const connectionStore = useConnectionStore();
  connectionStore.setConnectId();
  console.error("WebSocket error:", error);
};
