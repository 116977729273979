<template>
  <v-dialog
    :value="value"
    width="400"
    @keydown.esc="handleClose"
    @click:outside="handleClose"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        {{ date }} 新北QR Code管理
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-model="qrCode"
          class="mt-8"
          label="QR Code Body"
          outlined
        ></v-text-field>
        <div class="text-center">
          <qrcode-vue :value="qrCode" :size="200" level="L" />
        </div>
        <v-textarea
          class="mt-8"
          label="當天看診病人Email"
          outlined
          :value="emails"
          disabled
          :loading="isLoading"
        ></v-textarea>
      </v-card-text>
      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn
          depressed
          color="primary"
          :disabled="shouldDisableSave"
          @click="handleSave"
        >
          {{ LABELS.save }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useAppointmentStore } from "@/stores/appointment";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { usePatientStore } from "@/stores/patient";
import { useDayQrCodeStore } from "@/stores/dayQrCode";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import QrcodeVue from "qrcode.vue";
import { LABELS } from "@/shared/sharedLabels";

interface PropType {
  value: boolean;
  date: string;
}

const props = defineProps<PropType>();
const emit = defineEmits(["close"]);
const appointmentCalendarStore = useAppointmentCalendarStore();
const appointmentStore = useAppointmentStore();
const patientStore = usePatientStore();
const dayQrCodeStore = useDayQrCodeStore();
const isLoading = ref(false);
const qrCode = ref("");
const { timeSlotsForCurrentDate } = storeToRefs(appointmentCalendarStore);
const { appointments } = storeToRefs(appointmentStore);
const isSaving = ref(false);

const shouldDisableSave = computed(() => {
  return !qrCode.value.trim() || isSaving.value;
});

watch(
  () => {
    return dayQrCodeStore.dayQrCodes[props.date];
  },
  () => {
    qrCode.value = dayQrCodeStore.dayQrCodes[props.date]?.code ?? "";
  }
);

onMounted(() => {
  appointmentCalendarStore.fetchDayQrCodeForCurrentDate();
});

const handleSave = async () => {
  isSaving.value = true;
  try {
    if (qrCode.value) {
      await dayQrCodeStore.createOrUpdateDayQrCode(props.date, {
        code: qrCode.value,
      });
      handleClose();
    }
  } finally {
    isSaving.value = false;
  }
};

const patientIdSet = computed(() => {
  const patientIdSet = new Set<string>();
  timeSlotsForCurrentDate.value.forEach((timeSlot) => {
    if (timeSlot.site === "new_taipei" && timeSlot.appointmentIds) {
      timeSlot.appointmentIds.forEach((appointmentId) => {
        const appointment = appointments.value[appointmentId];
        if (appointment?.locationType === "in_person") {
          patientIdSet.add(appointment.patientId);
        }
      });
    }
  });
  return patientIdSet;
});

watch(patientIdSet, async () => {
  const patientIds = Array.from(patientIdSet.value);
  await Promise.all(
    patientIds.map((patientId) => patientStore.fetchPatient(patientId))
  );
});

const emails = computed(() => {
  const patientIds = Array.from(patientIdSet.value);
  const patients = patientIds.map(
    (patientId) => patientStore.patients[patientId]
  );
  const emailArr = patients
    .filter((patient) => !!patient)
    .map((patient) => patient?.email)
    .filter((email) => !!email) as string[];
  return Array.from(new Set(emailArr)).join(";");
});

const handleClose = () => {
  emit("close");
};
</script>
