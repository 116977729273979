<template>
  <Calendar
    :supported-types="SUPPORTED_CALENDAR_TYPES"
    @prevClicked="handlePrevClick"
    @nextClicked="handleNextClick"
    @weekSelected="setIsWeekSelected"
  >
    <div class="scrollable-div">
      <AppointmentCalendarMonth
        v-if="appointmentCalendarStore.isMonthCalendarView"
      />
      <AppointmentCalendarCustomWeek
        v-else-if="appointmentCalendarStore.isCustomWeekView"
      />
      <AppointmentCalendarCategory
        v-else-if="appointmentCalendarStore.isCategoryView"
      />
    </div>
  </Calendar>
</template>

<script setup lang="ts">
import AppointmentCalendarMonth from "@/components/appointmentCalendarMonth/appointmentCalendarMonth.vue";
import AppointmentCalendarCategory from "@/components/appointmentCalendarCategory/appointmentCalendarCategory.vue";
import { CalendarType } from "@/shared/sharedEnums";
import Calendar from "@/components/calendar/calendar.vue";
import moment from "moment";
import { onMounted } from "vue";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { useAppointmentStore } from "@/stores/appointment";
import { useRoute } from "vue-router/composables";
import AppointmentCalendarCustomWeek from "../appointmentCalendarCustomWeek/appointmentCalendarCustomWeek.vue";

const SUPPORTED_CALENDAR_TYPES = [CalendarType.CATEGORY, CalendarType.MONTH];
const appointmentCalendarStore = useAppointmentCalendarStore();
const appointmentStore = useAppointmentStore();

onMounted(() => {
  appointmentStore.fetchAppointmentMetadata();
  if (useRoute().query.date) {
    appointmentCalendarStore.setDate(useRoute().query.date as string);
  }
});

const setIsWeekSelected = (isSelected: boolean) => {
  appointmentCalendarStore.setIsCustomWeekMode(isSelected);
};

const handlePrevClick = (): void => {
  const unit =
    appointmentCalendarStore.calendarType === CalendarType.CATEGORY
      ? "day"
      : appointmentCalendarStore.isCustomWeekView
      ? "week"
      : "month";
  appointmentCalendarStore.setDate(
    moment(appointmentCalendarStore.date).subtract(1, unit).format("YYYY-MM-DD")
  );
};

const handleNextClick = (): void => {
  const unit =
    appointmentCalendarStore.calendarType === CalendarType.CATEGORY
      ? "day"
      : appointmentCalendarStore.isCustomWeekView
      ? "week"
      : "month";
  appointmentCalendarStore.setDate(
    moment(appointmentCalendarStore.date).add(1, unit).format("YYYY-MM-DD")
  );
};
</script>

<style>
.scrollable-div {
  overflow-x: hidden; /* Disable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 85vh;
}
</style>
