import {
  AppointmentGroupDTO,
  AppointmentGroupEditInput,
  ReceiptDTO,
  DeriveFeeFieldsInput,
} from "@/types";
import { axiosPost } from "@/utils/axios";
import axios from "axios";
import { defineStore } from "pinia";

interface State {
  appointmentGroups: Record<string, AppointmentGroupDTO | undefined>;
  appointmentGroupsFetchedForPatientIds: Set<string>;
}

export const useAppointmentGroupStore = defineStore("appointmentGroup", {
  state: (): State => ({
    appointmentGroups: {},
    appointmentGroupsFetchedForPatientIds: new Set<string>(),
  }),
  actions: {
    async fetchAppointmentGroups(patientId: string, force = false) {
      if (!force && this.appointmentGroupsFetchedForPatientIds.has(patientId)) {
        return;
      }
      const { data }: { data: AppointmentGroupDTO[] } = await axios.get(
        `patients/${patientId}/appointment-groups`
      );
      this.setAppointmentGroups(data);
      this.appointmentGroupsFetchedForPatientIds.add(patientId);
      return data;
    },
    async fetchAppointmentGroup(id: string, force = false) {
      if (this.appointmentGroups[id] && !force) {
        return this.appointmentGroups[id];
      }
      const { data }: { data: AppointmentGroupDTO | undefined } =
        await axios.get(`appointment-groups/${id}`);
      if (data) {
        this.setAppointmentGroups([data]);
      }
      return data;
    },
    async updateAppointmentGroup(
      appointmentGroupId: string,
      payload: Partial<AppointmentGroupEditInput>
    ): Promise<void> {
      const { data: appointmentGroup }: { data: AppointmentGroupDTO } =
        await axios.patch(`appointment-groups/${appointmentGroupId}`, payload);
      this.setAppointmentGroups([appointmentGroup]);
    },
    async fetchAppointmentGroupReceipt(
      appointmentGroupId: string
    ): Promise<ReceiptDTO> {
      const { data: receipt }: { data: ReceiptDTO } = await axios.get(
        `appointment-groups/${appointmentGroupId}/receipt`
      );
      return receipt;
    },
    async deleteAppointmentGroup(
      appointmentGroupId: string,
      deleteReason: string | undefined
    ): Promise<void> {
      // Vue.delete(this.appointmentGroups, appointmentGroupId);// 假如刪除appointmentGroup的話，總覽畫面的東西會消失
      await axios.delete(`appointment-groups/${appointmentGroupId}`, {
        params: { deleteReason },
      });
      const apptGroup = this.appointmentGroups[appointmentGroupId];
      // 假如刪除appointmentGroup的話，把他改成isDeleted:true，更新總覽表的資料
      this.setAppointmentGroups([{ ...apptGroup!, isDeleted: true }]);
    },
    async deriveFeeFields(
      deriveFeeFields: DeriveFeeFieldsInput
    ): Promise<Partial<AppointmentGroupDTO>> {
      const { data: derivedFeeFields }: { data: Partial<AppointmentGroupDTO> } =
        await axios.post(
          `appointment-groups/derive-fee-fields`,
          deriveFeeFields
        );
      return derivedFeeFields;
    },
    async sendBloodTestRequisition(appointmentGroupId: string): Promise<void> {
      try {
        const appointmentGroup = await axiosPost<AppointmentGroupDTO>(
          `appointment-groups/${appointmentGroupId}/blood-test-requisition`
        );
        this.setAppointmentGroups([appointmentGroup]);
      } catch (e: any) {
        if (e?.message) {
          window.alert(e?.message);
        }
      }
    },
    setAppointmentGroups(appointmentGroups: AppointmentGroupDTO[]): void {
      const newAppointmentGroups: {
        [appointmentGroupId: string]: AppointmentGroupDTO;
      } = {};
      appointmentGroups.forEach((appointmentGroup) => {
        newAppointmentGroups[appointmentGroup._id] = appointmentGroup;
      });
      this.appointmentGroups = {
        ...this.appointmentGroups,
        ...newAppointmentGroups,
      };
    },
    handleWsCreate(id: string) {
      if (this.appointmentGroups[id]) {
        this.fetchAppointmentGroup(id, true);
      }
    },
  },
  getters: {
    getAppointmentGroupsForPatient: (state) => {
      return (patientId: string) => {
        // @ts-ignore
        return Object.values<AppointmentGroupDTO>(state.appointmentGroups)
          .filter((appointmentGroup) => {
            return (
              appointmentGroup.patientId === patientId &&
              !appointmentGroup.isDeleted // 刪除了以後，appointmentGroup不會消失，因為總覽表需要看到已刪除的
            );
          })
          .sort((a, b) => {
            return (a.firstAppointmentDate ?? "").localeCompare(
              b.firstAppointmentDate ?? ""
            );
          });
      };
    },
  },
});
