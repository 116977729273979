<template>
  <v-calendar
    weekdays="1,2,3,4,5,6"
    type="custom-weekly"
    :start="appointmentCalendarStore.date"
    :end="endDate"
    @click:day="handleDateClick"
    @click:date="handleDateClick"
  >
    <template #day-label="{ date }">
      {{ moment(date).format("M/D") }}
      {{
        pregnantWeek(date, dueDate) ? `(${pregnantWeek(date, dueDate)})` : ""
      }}
    </template>
    <template #day="{ date }">
      <div class="d-flex flex-column" @click="focusOnDate(date)">
        <div class="day-wrapper">
          <ul style="list-style-type: none; padding-left: 0">
            <li
              v-if="
                !isDoctorUnscheduled(date, 'new_taipei') &&
                getDoctorsAndAvailability(
                  date,
                  'new_taipei',
                  monthlyCalendarViewMode === 'show_all'
                )?.length
              "
            >
              總
              <span
                v-for="doctorsAndAvailability in getDoctorsAndAvailability(
                  date,
                  'new_taipei',
                  monthlyCalendarViewMode === 'show_all'
                )"
                :key="doctorsAndAvailability.doctor._id"
                :class="`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`"
                :style="`background-color: ${doctorsAndAvailability.doctor.color};`"
              ></span>
            </li>
            <li
              v-if="
                !isDoctorUnscheduled(date, 'taipei') &&
                getDoctorsAndAvailability(
                  date,
                  'taipei',
                  monthlyCalendarViewMode === 'show_all'
                )?.length
              "
            >
              台
              <span
                v-for="doctorsAndAvailability in getDoctorsAndAvailability(
                  date,
                  'taipei',
                  monthlyCalendarViewMode === 'show_all'
                )"
                :key="doctorsAndAvailability.doctor._id"
                :class="`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`"
                :style="`background-color: ${doctorsAndAvailability.doctor.color};`"
              ></span>
            </li>
            <li
              v-if="
                !isDoctorUnscheduled(date, 'chupei') &&
                getDoctorsAndAvailability(
                  date,
                  'chupei',
                  monthlyCalendarViewMode === 'show_all'
                )?.length
              "
            >
              竹
              <span
                v-for="doctorsAndAvailability in getDoctorsAndAvailability(
                  date,
                  'chupei',
                  monthlyCalendarViewMode === 'show_all'
                )"
                :key="doctorsAndAvailability.doctor._id"
                :class="`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`"
                :style="`background-color: ${doctorsAndAvailability.doctor.color};`"
              ></span>
            </li>
            <li
              v-if="
                !isDoctorUnscheduled(date, 'miaoli') &&
                getDoctorsAndAvailability(
                  date,
                  'miaoli',
                  monthlyCalendarViewMode === 'show_all'
                )?.length
              "
            >
              苗
              <span
                v-for="doctorsAndAvailability in getDoctorsAndAvailability(
                  date,
                  'miaoli',
                  monthlyCalendarViewMode === 'show_all'
                )"
                :key="doctorsAndAvailability.doctor._id"
                :class="`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`"
                :style="`background-color: ${doctorsAndAvailability.doctor.color};`"
              ></span>
            </li>
            <li
              v-if="
                !isDoctorUnscheduled(date, 'yuanlin') &&
                getDoctorsAndAvailability(
                  date,
                  'yuanlin',
                  monthlyCalendarViewMode === 'show_all'
                )?.length
              "
            >
              員
              <span
                v-for="doctorsAndAvailability in getDoctorsAndAvailability(
                  date,
                  'yuanlin',
                  monthlyCalendarViewMode === 'show_all'
                )"
                :key="doctorsAndAvailability.doctor._id"
                :class="`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`"
                :style="`background-color: ${doctorsAndAvailability.doctor.color};`"
              ></span>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </v-calendar>
</template>

<script setup lang="ts">
import { CalendarType } from "@/shared/sharedEnums";
import { computed } from "vue";
import { pregnantWeek } from "@/utils/utils";
import { useDoctorAvailability } from "@/composables/useDoctorAvailability";
import { useAppointmentCalendarStore } from "@/stores/appointmentCalendar";
import { storeToRefs } from "pinia";
import moment from "moment";

const { getDoctorsAndAvailability, isDoctorUnscheduled } =
  useDoctorAvailability();
const appointmentCalendarStore = useAppointmentCalendarStore();
const { dueDate, monthlyCalendarViewMode } = storeToRefs(
  appointmentCalendarStore
);

const handleDateClick = (event: { date: string }): void => {
  focusOnDate(event.date);
};

const focusOnDate = (date: string): void => {
  appointmentCalendarStore.setCalendarType(CalendarType.CATEGORY);
  appointmentCalendarStore.setDate(date);
};

const endDate = computed(() => {
  return moment(appointmentCalendarStore.date)
    .add(3, "weeks")
    .subtract(1, "day")
    .format("YYYY-MM-DD");
});
</script>

<style lang="scss" scoped>
$circle-size: 22px;

.day-wrapper {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-availability-circle {
  cursor: pointer;

  &.top,
  &.bottom {
    height: $circle-size / 2;
    width: $circle-size;
  }

  &.top.bottom {
    height: $circle-size;
    width: $circle-size;
  }

  &.top {
    border-top-left-radius: $circle-size * 2;
    border-top-right-radius: $circle-size * 2;
    vertical-align: top;
  }

  &.bottom {
    border-bottom-left-radius: $circle-size * 2;
    border-bottom-right-radius: $circle-size * 2;
    vertical-align: bottom;
  }
}
</style>

<style>
.v-calendar-weekly__week {
  min-height: auto !important;
}
</style>
