import { render, staticRenderFns } from "./appointmentCalendarCustomWeek.vue?vue&type=template&id=a3b82d12&scoped=true"
import script from "./appointmentCalendarCustomWeek.vue?vue&type=script&setup=true&lang=ts"
export * from "./appointmentCalendarCustomWeek.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./appointmentCalendarCustomWeek.vue?vue&type=style&index=0&id=a3b82d12&prod&lang=scss&scoped=true"
import style1 from "./appointmentCalendarCustomWeek.vue?vue&type=style&index=1&id=a3b82d12&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b82d12",
  null
  
)

export default component.exports