import { useDoctorStore } from "@/stores/doctor";
import { useOverviewStore } from "@/stores/overview";
import { ClinicSite, Doctor } from "@/types";

enum DoctorAvailability {
  AM_AVAILABLE = "am_available",
  PM_AVAILABLE = "pm_available",
  AM_PM_AVAILABLE = "am_pm_available",
  UNAVAILABLE = "unavailable",
  UNSCHEDULED = "unscheduled",
}

export type DoctorWithAvailability = {
  doctor: Doctor;
  availability: DoctorAvailability;
  klass?: string;
};

export function useDoctorAvailability() {
  const overviewStore = useOverviewStore();
  const doctorStore = useDoctorStore();

  const getDoctorsAndAvailability = (
    date: string,
    site: ClinicSite,
    showAll?: boolean
  ) => {
    const overviewsAtDate = overviewStore.getOverviewsByDate(date);
    let isAmAvailable = false;
    let isPmAvailable = false;
    if (overviewsAtDate !== null && overviewsAtDate.length !== 0) {
      const doctorWithAvailability: Array<DoctorWithAvailability> = [];
      const doctors = Object.values(doctorStore.doctors);
      if (doctors !== null && doctors.length !== 0) {
        overviewsAtDate.forEach((overview) => {
          if (overview.site === site) {
            const doctorIdsInMetadata = Object.keys(overview.metadata);
            let doctorAvailability;
            doctors.forEach((doctor) => {
              if (doctorIdsInMetadata.includes(doctor._id)) {
                const metadata = overview.metadata[doctor._id];
                if (metadata) {
                  metadata.am && (showAll || metadata.total > metadata.booked)
                    ? (isAmAvailable = true)
                    : (isAmAvailable = false);
                  metadata.pm && (showAll || metadata.total > metadata.booked)
                    ? (isPmAvailable = true)
                    : (isPmAvailable = false);
                }
                if (isAmAvailable && isPmAvailable) {
                  doctorAvailability = DoctorAvailability.AM_PM_AVAILABLE;
                } else if (isAmAvailable) {
                  doctorAvailability = DoctorAvailability.AM_AVAILABLE;
                } else if (isPmAvailable) {
                  doctorAvailability = DoctorAvailability.PM_AVAILABLE;
                } else {
                  doctorAvailability = DoctorAvailability.UNAVAILABLE;
                }
                doctorWithAvailability.push({
                  doctor,
                  availability: doctorAvailability,
                  klass: getAvailabilityCircleClass(doctorAvailability),
                });
              }
            });
          }
        });
        return doctorWithAvailability;
      }
    }
  };

  const getAvailabilityCircleClass = (
    availability: DoctorAvailability
  ): string => {
    switch (availability) {
      case DoctorAvailability.AM_AVAILABLE:
        return "top";
      case DoctorAvailability.PM_AVAILABLE:
        return "bottom";
      case DoctorAvailability.AM_PM_AVAILABLE:
        return "top bottom";
      default:
        return "";
    }
  };

  const isDoctorUnscheduled = (date: string, site: ClinicSite): boolean => {
    const doctorsAndAvailability = getDoctorsAndAvailability(date, site);
    if (doctorsAndAvailability && doctorsAndAvailability.length === 0) {
      return true;
    }
    return false;
  };

  return {
    getDoctorsAndAvailability,
    isDoctorUnscheduled,
    getAvailabilityCircleClass,
  };
}
