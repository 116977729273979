var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-calendar',{attrs:{"weekdays":"1,2,3,4,5,6","type":"custom-weekly","start":_setup.appointmentCalendarStore.date,"end":_setup.endDate},on:{"click:day":_setup.handleDateClick,"click:date":_setup.handleDateClick},scopedSlots:_vm._u([{key:"day-label",fn:function({ date }){return [_vm._v(" "+_vm._s(_setup.moment(date).format("M/D"))+" "+_vm._s(_setup.pregnantWeek(date, _setup.dueDate) ? `(${_setup.pregnantWeek(date, _setup.dueDate)})` : "")+" ")]}},{key:"day",fn:function({ date }){return [_c('div',{staticClass:"d-flex flex-column",on:{"click":function($event){return _setup.focusOnDate(date)}}},[_c('div',{staticClass:"day-wrapper"},[_c('ul',{staticStyle:{"list-style-type":"none","padding-left":"0"}},[(
              !_setup.isDoctorUnscheduled(date, 'new_taipei') &&
              _setup.getDoctorsAndAvailability(
                date,
                'new_taipei',
                _setup.monthlyCalendarViewMode === 'show_all'
              )?.length
            )?_c('li',[_vm._v(" 總 "),_vm._l((_setup.getDoctorsAndAvailability(
                date,
                'new_taipei',
                _setup.monthlyCalendarViewMode === 'show_all'
              )),function(doctorsAndAvailability){return _c('span',{key:doctorsAndAvailability.doctor._id,class:`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`,style:(`background-color: ${doctorsAndAvailability.doctor.color};`)})})],2):_vm._e(),(
              !_setup.isDoctorUnscheduled(date, 'taipei') &&
              _setup.getDoctorsAndAvailability(
                date,
                'taipei',
                _setup.monthlyCalendarViewMode === 'show_all'
              )?.length
            )?_c('li',[_vm._v(" 台 "),_vm._l((_setup.getDoctorsAndAvailability(
                date,
                'taipei',
                _setup.monthlyCalendarViewMode === 'show_all'
              )),function(doctorsAndAvailability){return _c('span',{key:doctorsAndAvailability.doctor._id,class:`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`,style:(`background-color: ${doctorsAndAvailability.doctor.color};`)})})],2):_vm._e(),(
              !_setup.isDoctorUnscheduled(date, 'chupei') &&
              _setup.getDoctorsAndAvailability(
                date,
                'chupei',
                _setup.monthlyCalendarViewMode === 'show_all'
              )?.length
            )?_c('li',[_vm._v(" 竹 "),_vm._l((_setup.getDoctorsAndAvailability(
                date,
                'chupei',
                _setup.monthlyCalendarViewMode === 'show_all'
              )),function(doctorsAndAvailability){return _c('span',{key:doctorsAndAvailability.doctor._id,class:`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`,style:(`background-color: ${doctorsAndAvailability.doctor.color};`)})})],2):_vm._e(),(
              !_setup.isDoctorUnscheduled(date, 'miaoli') &&
              _setup.getDoctorsAndAvailability(
                date,
                'miaoli',
                _setup.monthlyCalendarViewMode === 'show_all'
              )?.length
            )?_c('li',[_vm._v(" 苗 "),_vm._l((_setup.getDoctorsAndAvailability(
                date,
                'miaoli',
                _setup.monthlyCalendarViewMode === 'show_all'
              )),function(doctorsAndAvailability){return _c('span',{key:doctorsAndAvailability.doctor._id,class:`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`,style:(`background-color: ${doctorsAndAvailability.doctor.color};`)})})],2):_vm._e(),(
              !_setup.isDoctorUnscheduled(date, 'yuanlin') &&
              _setup.getDoctorsAndAvailability(
                date,
                'yuanlin',
                _setup.monthlyCalendarViewMode === 'show_all'
              )?.length
            )?_c('li',[_vm._v(" 員 "),_vm._l((_setup.getDoctorsAndAvailability(
                date,
                'yuanlin',
                _setup.monthlyCalendarViewMode === 'show_all'
              )),function(doctorsAndAvailability){return _c('span',{key:doctorsAndAvailability.doctor._id,class:`doctor-availability-circle d-inline-block ma-1 ${doctorsAndAvailability.klass}`,style:(`background-color: ${doctorsAndAvailability.doctor.color};`)})})],2):_vm._e()])])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }