import { Appointment } from "@/shared/sharedTypes";
import { AppointmentMetadata, CheckTypeMetadata } from "@/types";
import {
  AppointmentDTO,
  AppointmentEditInput,
  AppointmentGroupDTO,
  BulkCreateAppointmentInputDTO,
  CheckType,
  Patient,
  AppointmentFilterInput,
} from "@/types";
import axios from "axios";
import { defineStore } from "pinia";
import Vue from "vue";
import { usePatientStore } from "./patient";
import { useAppointmentGroupStore } from "./appointmentGroup";
import { useReceiptStore } from "./receipt";

interface State {
  appointments: Record<string, Appointment | undefined>;
  appointmentMetadata?: AppointmentMetadata;
  checkTypeMetadata?: CheckTypeMetadata;
}

export const useAppointmentStore = defineStore("appointment", {
  state: (): State => ({
    appointments: {},
    appointmentMetadata: undefined,
  }),
  actions: {
    async fetchAppointments(filterInput?: AppointmentFilterInput) {
      const { data } = await axios.get<{
        items: AppointmentDTO[];
        totalCount: number;
      }>("appointments", {
        params: filterInput,
      });
      this.setAppointments(data.items);
      return data;
    },
    async fetchAppointment(appointmentId: string): Promise<AppointmentDTO> {
      const { data }: { data: AppointmentDTO } = await axios.get(
        `appointments/${appointmentId}`
      );
      this.setAppointments([data]);
      return data;
    },
    async fetchAppointmentsForAppointmentGroup(
      appointmentGroupId: string
    ): Promise<AppointmentDTO[]> {
      const { data }: { data: AppointmentDTO[] } = await axios.get(
        `appointment-groups/${appointmentGroupId}/appointments`
      );
      this.setAppointments(data);
      return data;
    },
    async deleteAppointment(
      appointmentId: string,
      deleteReason?: string
    ): Promise<void> {
      const appointment = this.appointments[appointmentId];
      if (appointment) {
        await axios.delete(`appointments/${appointmentId}`, {
          params: { deleteReason },
        });
        Vue.delete(this.appointments, appointmentId);
      }
    },
    async updateAppointment(
      appointmentId: string,
      payload: AppointmentEditInput
    ): Promise<void> {
      const { data: appointment }: { data: AppointmentDTO } = await axios.put(
        `appointments/${appointmentId}`,
        payload
      );
      this.setAppointments([appointment]);
    },
    async confirmAppointment(
      appointmentId: string,
      isConfirmed: boolean
    ): Promise<void> {
      await axios.post(`appointments/${appointmentId}/confirm`, {
        isConfirmed,
      });
    },
    async createAppointments(
      payload: BulkCreateAppointmentInputDTO
    ): Promise<AppointmentDTO[] | undefined> {
      try {
        const { data }: { data: AppointmentDTO[] } = await axios.post(
          `appointments/bulk`,
          payload
        );
        this.setAppointments(data);
        return data;
      } catch (e: any) {
        if (e?.response?.data?.message) {
          window.alert(e.response.data.message);
        }
      }
    },
    async checkin(appointmentId: string): Promise<void> {
      const { data }: { data: AppointmentDTO } = await axios.post(
        `appointments/${appointmentId}/check-in`
      );
      this.setAppointments([data]);
      useAppointmentGroupStore().fetchAppointmentGroup(
        data.appointmentGroupId,
        true
      );
      useReceiptStore().fetchReceiptsForAppointmentGroup(
        data.appointmentGroupId,
        true
      );
    },
    async sendReminderNotice(appointmentId: string): Promise<void> {
      const { data }: { data: AppointmentDTO } = await axios.post(
        `appointments/${appointmentId}/reminder-notice`
      );
      this.setAppointments([data]);
    },
    sendAppointmentConfirmationEmail(appointmentId: string): Promise<void> {
      return axios.post(`appointments/${appointmentId}/confirmation-email`);
    },
    sendAppointmentPaymentInfoEmail(appointmentId: string): Promise<void> {
      return axios.post(`appointments/${appointmentId}/payment-info-email`);
    },
    async fetchAppointmentMetadata(): Promise<void> {
      if (this.appointmentMetadata) {
        return;
      }
      const {
        data,
      }: {
        data: {
          APPOINTMENT_METADATA: AppointmentMetadata;
          CHECK_TYPE_METADATA: CheckTypeMetadata;
        };
      } = await axios.get("appointments/metadata");
      this.appointmentMetadata = data.APPOINTMENT_METADATA;
      this.checkTypeMetadata = data.CHECK_TYPE_METADATA;
    },
    setAppointments(rawAppointments: AppointmentDTO[]): void {
      const newAppointments: Record<string, Appointment> = {};
      const newPatients: Patient[] = [];
      const newAppointmentGroups: AppointmentGroupDTO[] = [];
      rawAppointments.forEach((rawAppointment) => {
        const { patient, appointmentGroup, ...rest } = rawAppointment;
        newAppointments[rawAppointment._id] = rest;
        if (patient) {
          newPatients.push(patient);
        }
        if (appointmentGroup) {
          newAppointmentGroups.push(appointmentGroup);
        }
      });
      this.appointments = { ...this.appointments, ...newAppointments };
      const patientStore = usePatientStore();
      patientStore.setPatients(newPatients);
      const appointmentGroupStore = useAppointmentGroupStore();
      appointmentGroupStore.setAppointmentGroups(newAppointmentGroups);
    },
    setAppointmentMetadata(appointmentMetadata: AppointmentMetadata): void {
      this.appointmentMetadata = appointmentMetadata;
    },
    deleteAppointmentFromStore(id: string): void {
      Vue.delete(this.appointments, id);
    },
    handleWsCreate(id: string) {
      if (this.appointments[id]) {
        this.fetchAppointment(id);
      }
    },
  },
  getters: {
    getAppointmentsForAppointmentGroup: (state) => {
      return (appointmentGroupId: string) => {
        const appointmentsForGroup: Appointment[] = [];
        Object.keys(state.appointments).forEach((id) => {
          const appointment = state.appointments[id];
          if (appointment?.appointmentGroupId === appointmentGroupId) {
            appointmentsForGroup.push(appointment);
          }
        });
        appointmentsForGroup.sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        );
        return appointmentsForGroup;
      };
    },
    getFirstAppointmentForAppointmentGroup: (state) => {
      return (appointmentGroupId: string) => {
        const appointmentsForGroup: Appointment[] = [];
        Object.keys(state.appointments).forEach((id) => {
          const appointment = state.appointments[id];
          if (appointment?.appointmentGroupId === appointmentGroupId) {
            appointmentsForGroup.push(appointment);
          }
        });
        const appointmentGroupStore = useAppointmentGroupStore();
        const appointmentGroup =
          appointmentGroupStore.appointmentGroups[appointmentGroupId];
        if (!state.appointmentMetadata || !appointmentGroup) {
          return undefined;
        }
        const metadata = state.appointmentMetadata[appointmentGroup.type];
        const firstCheckType = Object.keys(metadata.checkTypes)[0];
        return appointmentsForGroup.find((appointment) => {
          return appointment.checkTypes.includes(firstCheckType as CheckType);
        });
      };
    },
  },
});
